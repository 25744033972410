import React, { useCallback, useEffect, useRef } from 'react';
import Input, { InputProps } from 'components/Input/Input';
interface InputPlacesProps extends Omit<InputProps, 'onChange'> {
  options?: google.maps.places.AutocompleteOptions;
  onChange: (query: string | undefined, place?: google.maps.places.PlaceResult) => void;
}

type BoundsType = { southWest: [number, number]; northEast: [number, number]; };
type LatLngBounds = google.maps.LatLngBounds;

const InputPlaces_BOUNDS_ILLINOIS: BoundsType = {
  southWest: [36.966, -91.502],
  northEast: [42.511, -87.5]
};

const getBounds = (bounds: BoundsType): LatLngBounds => {
  const {
    southWest: [south, west],
    northEast: [north, east]
  } = bounds;
  const southWest = new window.google.maps.LatLng(south, west);
  const northEast = new window.google.maps.LatLng(north, east);
  const _bounds = new google.maps.LatLngBounds(southWest, northEast);
  return _bounds;
};

const InputPlaces: React.FC<InputPlacesProps> = ({ options, onChange, value, ...props }) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handlePlaceSelect = useCallback(async () => {
    const autocomplete = autocompleteRef.current;
    if (!autocomplete) return;
    const place = autocomplete.getPlace();
    const query = place?.formatted_address || inputRef.current?.value;
    onChange(query, place);
  }, [onChange]);

  useEffect(() => {
    // if (autoComplete !== undefined) return;
    if (!window.google.maps?.places) {
      // eslint-disable-next-line no-console
      console.error('Places api is not loaded');
      return;
    }

    const bounds = getBounds(InputPlaces_BOUNDS_ILLINOIS);

    const opts: google.maps.places.AutocompleteOptions = {
      types: ['address'],
      componentRestrictions: { country: 'us' },
      bounds,
      ...options
    };
    autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current!, opts);
    autocompleteRef.current.setFields(['address_components', 'formatted_address', 'geometry']);
    autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
  }, [options]);

  return (
    <Input
      ref={inputRef}
      defaultValue={value}
      onChange={e => onChange(e.target.value)}
      {...props}
    />
  );
};

export default InputPlaces;
