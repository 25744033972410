import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import SellProcessContext from '../context';
import ReviewSections from '../ReviewSections';
import FinancialsForm from './FinancialsForm';

interface FinancialsProps {
  open?: boolean;
}

const Financials: React.FC<FinancialsProps> = ({ open }) => {
  const {
    setActiveTab,
    propertyInfoTabData,
    propertyDetailsTabData,
    financialsTabData,
    propertyInfoInit,
    setFinancialsTabData
  } = useContext(SellProcessContext);

  const propertyValues = useMemo(
    () => ({
      name:
        propertyInfoInit?.name ||
        `${propertyInfoTabData?.firstName} ${propertyInfoTabData?.lastName}`,
      email: propertyInfoInit?.email || propertyInfoTabData?.email
    }),
    [propertyInfoInit, propertyInfoTabData]
  );

  const formDefaultValues = {
    ...financialsTabData,
    ...propertyValues
  };

  const onSubmit = values => {
    setFinancialsTabData(values);
    setActiveTab('review');
  };

  return (
    <StyledFinancials>
      {open && <ReviewSections />}
      <div
        className={clsx('info-block', {
          'info-block_merged-top': open
        })}>
        <p className={clsx('info-block__title', { 'info-block__title_primary': open })}>
          <span className="info-block__num">4</span>
          <span>Financials</span>
        </p>
        {!open && Boolean(propertyDetailsTabData) && (
          <button className="info-block__edit-btn" onClick={() => setActiveTab('financials')}>
            Edit
          </button>
        )}
      </div>

      {open && (
        <div className="info-block info-block_merged-bottom">
          <FinancialsForm defaultValues={formDefaultValues} onSubmit={onSubmit} />
        </div>
      )}
    </StyledFinancials>
  );
};

const StyledFinancials = styled.div`
  .process-form__input-block {
    &.seller-inputs {
      margin: 0 0 14px;
    }
  }
  .seller-title {
    margin: 36px 0 14px;
  }
`;

export default Financials;
