import React from 'react';
import ListingCarousel from 'components/ListingCarousel';
import styled from '@emotion/styled';
import { numberFormatter } from 'utils/formatters';
import noImage from 'assets/images/placeholder_no_image.jpg';
interface ListingInfoProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  data: ListingDetails;
}

const ListingInfo: React.FC<ListingInfoProps> = ({ data, ...props }) => {
  const addressLine1 = data.address1;
  return (
    <StuledListingInfo {...props}>
      {data.images.length > 0 ? (
        <ListingCarousel images={data.images} />
      ) : (
          <img src={noImage} className="img-error" alt="img-error" />
        )}
      <div className="listing-data scrollbar-custom">
        <h3 className="listing-data__title">{addressLine1}</h3>
        <div className="listing-data__row">
          {data.city}, {data.state} {data.postalCode}
        </div>
        <div className="listing-data__row">
          {data.propertyDetails.beds} Bed, {data.propertyDetails.baths} Bath •{' '}
          {numberFormatter.format(data.propertyDetails.sqft)} sqft
        </div>
        <hr />
        <h4>Features</h4>
        <div className="listing-data__row">Property type: {data.propertyType}</div>
        <div className="listing-data__row">Year built: {data.propertyDetails.yearBuilt}</div>
        <div className="listing-data__row">
          Size: {numberFormatter.format(data.propertyDetails.sqft)} ft²
        </div>
        <div className="listing-data__row">Lot size: {data.propertyDetails.lotSize || 0} ft²</div>
        <div className="listing-data__row">
          # of Parking Spaces: {data.propertyDetails.parkingSpaces || 0}
        </div>
        <div className="listing-data__row">Last Year Taxes: {data.propertyTaxes}</div>
        <div className="listing-data__row">Description: {data.description}</div>
      </div>
    </StuledListingInfo>
  );
};

export default ListingInfo;

const StuledListingInfo = styled.div`
  display: flex;
  height: 360px;
  padding: 0 !important;
  overflow: hidden;

  .listing-carousel, .img-error{
    max-width: 570px;
  }

  .listing-data {
    padding: 24px;
    height: 100%;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      background: #ababab;
      border-radius: 5px;
    }

    & > * {
      margin: 4px 0;
    }

    hr {
      margin: 16px 0;
    }

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #9c9c9e;
    }

    &__title {
      margin: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }

    &__row {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 260px;
    flex-direction: column;

    .listing-carousel, .img-error {
      flex-shrink: 0;
      max-width: unset;
      width: 100%;
      height: 155px;
    }

    .listing-data {
      padding: 16px;

      &__title,
      &__row {
        font-size: 14px;
        line-height: 24px;
        margin: 0;
      }
    }
  }
`;
