import { urlEncodeBody } from 'utils';
import { mapListing } from 'utils/mapping';
import { fetchWithToken } from 'api';

//
export async function submitSellProcessForm(values, listingId: string): Promise<void> {
  const data = {
    data: JSON.stringify(values),
    listing_id: listingId
  };
  const response = await fetchWithToken('/sell', { method: 'PUT', body: urlEncodeBody(data) });
  const jsonData = await response.json();
  if (!jsonData.update_or_insert) throw Error('Form submission failed.');
}

export async function obtainMlsId(
  address: string
): Promise<{
  listingMlsId: string;
  listing: ListingDetails;
}> {
  const response = await fetchWithToken(`listings/obtain_mls_id?address=${address}`);
  const data = await response.json();
  return {
    listingMlsId: data.listing_id,
    listing: mapListing(data.listing) as ListingDetails
  };
}
