import React from 'react';
import { Link } from 'gatsby';
import { Icon } from 'components';

import styled from '@emotion/styled';

interface FormSubmitConfirmationProps {
  email?: string;
}

const FormSubmitConfirmation: React.FC<FormSubmitConfirmationProps> = ({ email }) => {
  return (
    <StyledFormSubmitConfirmation className="process-confirm">
      <div className="info-block">
        <div className="process-confirm__title">
          <span className="circle">
            <Icon name="check" />
          </span>
          <span>
            Thank you! Your listing is under review. We will send you an email once your listing is
            posted
          </span>
        </div>
      </div>
      <div className="info-block process-confirm-block">
        <div className="process-confirm__title process-confirm__title-bold">
          Confirmation of your listing has been sent to {email}
        </div>
        <div className="process-confirm__info">
          Please check your email for confirmation. To go to home page click Home
        </div>
        <div className="info-block__btn-block">
          <Link to="/" className="process-confirm-btn">
            Home
          </Link>
        </div>
      </div>
    </StyledFormSubmitConfirmation>
  );
};

export default React.memo(FormSubmitConfirmation);

const StyledFormSubmitConfirmation = styled.div`
  .process-confirm {
    &-block {
      display: block;
    }
    &__title {
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: 24px;
      &-bold {
        font-weight: 500;
      }
    }
    &__info {
      margin: 12px 0 0;
      font-size: 14px;
      line-height: 24px;
    }
    &-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      min-width: 164px;
      font-size: 12px;
      line-height: 14px;
      color: #fff;
      border-radius: ${props => props.theme.misc.borderRadius};
      background: ${props => props.theme.colors.primary};
      text-decoration: none;
    }
  }
  .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 28px;
    margin-right: 24px;

    .icon-check {
      fill: ${props => props.theme.colors.primary};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .process-confirm {
      &__title {
        flex-flow: column;
        .circle {
          margin: 0 0 12px;
        }
        .icon-check {
          flex-shrink: 0;
        }
      }
    }
  }
`;
