import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import pluralize from 'pluralize';
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  FileInput,
  DateInput,
  RadioButton,
  Textarea
} from 'components';
import { disablePastDates, disableFutureDates } from 'utils/date';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { uploadFileToS3 } from 'api/documents';
import propertyDetailsSchema from '../formSchema/propertyDetailsSchema.json';

dayjs.extend(isSameOrBefore);

const currentYear = dayjs().year();

const formatDate = value => {
  return dayjs(value).format('MM/DD/YY');
};

interface PropertyDetailsProps {
  defaultValues?: { [key: string]: any; };
  condo?: boolean;
  listingId?: any;
  onSubmit: (values: { [key: string]: any; }) => void;
}

const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  defaultValues,
  condo,
  listingId,
  onSubmit
}) => {
  const { register, errors, control, setValue, getValues, handleSubmit } = useForm({
    defaultValues
  });
  const [showMore, setShowMore] = useState(false);
  const hasErrors = Object.keys(errors).length > 0;

  const onFileUpload = async fileToAdd => {
    try {
      const { url, documentId } = await uploadFileToS3(fileToAdd, { publicAccess: true, listingId, doctype: 'Listing Photos' });
      const files = getValues('property_images');
      setValue('property_images', [...files, { id: documentId, url, name: fileToAdd.name }]);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  const onFileRemove = fileToremove => {
    const files = getValues('property_images').filter(file => file.id !== fileToremove.id);
    setValue('property_images', files);
  };

  const getFilesLabel = files => {
    const { length } = files;
    if (!length) return 'Upload';
    return `${length} ${pluralize('file', length)}`;
  };

  const handleContinue = values => {
    if (hasErrors) return;
    if (!showMore) setShowMore(true);
    else onSubmit(values);
  };

  return (
    <form className="process-form" onSubmit={handleSubmit(handleContinue)}>
      <h3 className="process-form__title">My Property is:</h3>
      <div className="process-form__input-block">
        <Controller
          control={control}
          rules={{
            required: 'Date required'
          }}
          name="available_on"
          render={({ onChange, ...controllerProps }) => (
            <DateInput
              inputProps={{
                label: 'Available on*',
                placeholder: 'MM/DD/YY',
                error: errors.available_on
              }}
              isValidDate={disablePastDates}
              onChange={value => onChange(formatDate(value))}
              className="process-form__input"
              {...controllerProps}
            />
          )}
        />
        <Controller
          control={control}
          rules={{
            required: 'Date required'
          }}
          name="purchased_on"
          render={({ onChange, ...controllerProps }) => (
            <DateInput
              inputProps={{
                label: 'Purchased on*',
                placeholder: 'MM/DD/YY',
                error: errors.purchased_on
              }}
              initialViewMode='years'
              isValidDate={disableFutureDates}
              onChange={value => onChange(formatDate(value))}
              className="process-form__input"
              {...controllerProps}
            />
          )}
        />
        <Input
          ref={register}
          name="community"
          label="Community"
          placeholder="Community"
          className="process-form__input"
        />
        <Input
          ref={register}
          name="property_tax_id"
          label="Property tax ID"
          placeholder="Enter property tax ID"
          className="process-form__input"
        />
      </div>
      <div className="process-form__textarea-block">
        <Textarea
          ref={register}
          name="property_description"
          label="Property Description"
          placeholder="Enter property description"
          className="process-form__textarea"
        />
      </div>
      <div className="process-form__input-block process-form__input-block_full-width">
        <Controller
          control={control}
          name="property_images"
          defaultValue={[]}
          render={controllerProps => (
            <FileInput
              label="Upload photos"
              placeholder={getFilesLabel(controllerProps.value)}
              onFileUpload={onFileUpload}
              onFileRemove={onFileRemove}
              files={controllerProps.value}
              multiple
              showSpinner
              {...controllerProps}
            />
          )}
        />
      </div>
      {showMore && (
        <>
          <div className="checkbox-wrapper">
            <div className="checkbox-section">
              <div className="checkbox-container">
                <div className="checkbox-container__title">Appliances</div>
                <div className="checkboxes checkboxes-col2">
                  {Object.keys(propertyDetailsSchema.appliances).map(key => (
                    <Checkbox
                      ref={register}
                      name={key}
                      label={propertyDetailsSchema.appliances[key].label}
                      key={key}
                    />
                  ))}
                </div>
              </div>
              <div className="checkbox-section">
                <div className="checkbox-container">
                  <div className="checkbox-container__title">Laundry</div>
                  <div className="checkboxes">
                    {propertyDetailsSchema.laundry.enum.map((item, index) => (
                      <RadioButton
                        ref={register}
                        label={item}
                        value={item}
                        id={`laundry[${index}]`}
                        name="laundry"
                        // eslint-disable-next-line react/no-array-index-key
                        key={`laundry[${index}]`}
                      />
                    ))}
                  </div>
                </div>
                <div className="checkbox-container">
                  <div className="checkbox-container__title">Pets</div>
                  <div className="checkboxes">
                    {Object.keys(propertyDetailsSchema.pets).map(key => (
                      <Checkbox
                        ref={register}
                        name={key}
                        label={propertyDetailsSchema.pets[key].label}
                        key={key}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="checkbox-wrapper">
            <div className="checkbox-section">
              <div className="checkbox-container">
                <div className="checkbox-container__title">Indoor Features</div>
                <div className="checkboxes checkboxes-col2">
                  {Object.keys(propertyDetailsSchema.indoor_features).map(key => (
                    <Checkbox
                      ref={register}
                      name={key}
                      label={propertyDetailsSchema.indoor_features[key].label}
                      key={key}
                    />
                  ))}
                </div>
              </div>
              <div className="checkbox-section">
                <div className="checkbox-container">
                  <div className="checkbox-container__title">Outdoor Features</div>
                  <div className="checkboxes checkboxes-col2">
                    {Object.keys(propertyDetailsSchema.outdoor_features).map(key => (
                      <Checkbox
                        ref={register}
                        name={key}
                        label={propertyDetailsSchema.outdoor_features[key].label}
                        key={key}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="checkbox-wrapper">
            <div className="checkbox-section">
              <div className="checkbox-container">
                <div className="checkbox-container__title">Facilities & Recreation</div>
                <div className="checkboxes checkboxes-col2">
                  {Object.keys(propertyDetailsSchema.facilities_and_recreation).map(key => (
                    <Checkbox
                      ref={register}
                      name={key}
                      label={propertyDetailsSchema.facilities_and_recreation[key].label}
                      key={key}
                    />
                  ))}
                </div>
              </div>
              <div className="checkbox-section">
                <div className="checkbox-container">
                  <div className="checkbox-container__title">Access & Security</div>
                  <div className="checkboxes checkboxes-col2">
                    {Object.keys(propertyDetailsSchema.access_and_security).map(key => (
                      <Checkbox
                        ref={register}
                        name={key}
                        label={propertyDetailsSchema.access_and_security[key].label}
                        key={key}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="process-form__input-block">
            <Input
              ref={register}
              defaultValue=""
              name="parking_spaces"
              label="Number of parking spaces"
              placeholder="Enter number of parking spaces"
              className="process-form__input"
            />
            <Controller
              control={control}
              name="parking_type"
              render={controllerProps => (
                <Dropdown
                  options={propertyDetailsSchema.parking_type.options}
                  label={propertyDetailsSchema.parking_type.label}
                  placeholder={propertyDetailsSchema.parking_type.placeholder}
                  className="process-form__input process-form__dropdown"
                  {...controllerProps}
                />
              )}
            />
            {!condo && (
              <>
                <Input
                  ref={register}
                  defaultValue=""
                  name="parking_number"
                  label="Parking Number"
                  placeholder="Enter parking number"
                  className="process-form__input"
                />
                <Input
                  ref={register}
                  defaultValue=""
                  name="parking_info"
                  label="Parking Information"
                  placeholder="Enter parking information"
                  className="process-form__input"
                />
                <Input
                  ref={register}
                  defaultValue=""
                  name="storage_number"
                  label="Storage Number"
                  placeholder="Enter storage number"
                  className="process-form__input"
                />
                <Input
                  ref={register}
                  defaultValue=""
                  name="storage_info"
                  label="Storage Information"
                  placeholder="Enter storage information"
                  className="process-form__input"
                />
              </>
            )}
            <Input
              ref={register({
                validate: value =>
                  value ? (value >= 0 && value <= currentYear) || 'Invalid value' : true
              })}
              name="year_built"
              label="Year of construction"
              placeholder="Enter year"
              type="number"
              error={errors.year_built}
              className="process-form__input"
            />
            <Controller
              control={control}
              name="utility_features"
              render={controllerProps => (
                <Dropdown
                  options={propertyDetailsSchema.utility_features.options}
                  label={propertyDetailsSchema.utility_features.label}
                  placeholder={propertyDetailsSchema.utility_features.placeholder}
                  className="process-form__input process-form__dropdown"
                  {...controllerProps}
                />
              )}
            />
            <Controller
              control={control}
              name="ac_heater"
              render={controllerProps => (
                <Dropdown
                  options={propertyDetailsSchema.ac_heater.options}
                  label={propertyDetailsSchema.ac_heater.label}
                  placeholder={propertyDetailsSchema.ac_heater.placeholder}
                  className="process-form__input process-form__dropdown"
                  {...controllerProps}
                />
              )}
            />
            <Controller
              control={control}
              name="flooring_type"
              render={controllerProps => (
                <Dropdown
                  options={propertyDetailsSchema.flooring_type.options}
                  label={propertyDetailsSchema.flooring_type.label}
                  placeholder={propertyDetailsSchema.flooring_type.placeholder}
                  className="process-form__input process-form__dropdown"
                  {...controllerProps}
                />
              )}
            />
          </div>
        </>
      )}
      <Button className="process-form__btn" primary>
        {showMore ? 'Next' : 'Continue'}
      </Button>
    </form>
  );
};

export default PropertyDetails;
