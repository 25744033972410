import React, { useContext } from 'react';

import { Tabs } from 'components';
import styled from '@emotion/styled';
import { Loader } from 'components';
import SellProcessContext, { SellProcessContextWrapper } from './context';
import Identify from './Identify';
import PropertyInfo from './PropertyInfo';
import PropertyDetails from './PropertyDetails';
import Financials from './Financials';
import Review from './Review/Review';
import FormSubmitConfirmation from './FormSubmitConfirmation';

const tabs: SellProcessTab[] = [
  { key: 'identify', text: '1 Identify Property' },
  { key: 'info', text: '2  Property Information' },
  { key: 'details', text: '3  Property Details' },
  { key: 'financials', text: '4  Financials' },
  { key: 'review', text: '5  Review' }
];

const SellProcessPageWrapper = () => {
  const {
    identifyTabData,
    propertyInfoTabData,
    propertyDetailsTabData,
    financialsTabData,
    activeTab,
    formSubmitted,
    setActiveTab,
    mlsListingLoading
  } = useContext(SellProcessContext);

  const getTabProps = tab => {
    let disabled = false;
    switch (tab.key) {
      case 'info':
        if (!identifyTabData) disabled = true;
        break;
      case 'details':
        if (!propertyInfoTabData) disabled = true;
        break;
      case 'financials':
        if (!propertyDetailsTabData) disabled = true;
        break;
      case 'review':
        if (!financialsTabData) disabled = true;
        break;
      default:
        break;
    }

    return { disabled };
  };

  const getTabProgress = tab => {
    let completed = false;
    switch (tab.key) {
      case 'identify':
        if (identifyTabData) completed = true;
        break;
      case 'info':
        if (propertyInfoTabData) completed = true;
        break;
      case 'details':
        if (propertyDetailsTabData) completed = true;
        break;
      case 'financials':
        if (financialsTabData) completed = true;
        break;
      default:
        break;
    }

    return { completed };
  };

  return (
    <StyledSellProcessWrapper className="process-wrapper">
      {formSubmitted ? (
        <div className="process-container">
          <FormSubmitConfirmation email={propertyInfoTabData?.email} />
        </div>
      ) : (
          <>
            <div className="process-tabs-container">
              <StyledTabs
                activeTab={activeTab}
                onSelect={(_e, value) => setActiveTab(value.key)}
                items={tabs}
                className="process-tabs"
                getTabStatus={getTabProgress}
                getTabProps={getTabProps}
              />
            </div>
            <div className="process-container">
              {mlsListingLoading && (
                <LoaderContainer>
                  <Loader>
                    <br />
                      We're searching your property
                    </Loader>
                </LoaderContainer>
              )}
              <Identify open={activeTab === 'identify'} />
              <PropertyInfo open={activeTab === 'info'} />
              <PropertyDetails open={activeTab === 'details'} />
              <Financials open={activeTab === 'financials'} />
              <Review open={activeTab === 'review'} />
            </div>
          </>
        )}
    </StyledSellProcessWrapper>
  );
};

const SellProcessPage: React.FC<{ path?: string; }> = () => (
  <SellProcessContextWrapper>
    <SellProcessPageWrapper />
  </SellProcessContextWrapper>
);

export default SellProcessPage;

const StyledSellProcessWrapper = styled.div`
  .container.header > .button {
    float: right;
  }

  /* styles before Container component */

  .process-tabs-container {
    background: black;
    display: flex;
    justify-content: center;
    padding: 20px 16px 0;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 36px;
  }
  .process-container {
    max-width: 1057px;
    width: 100%;
    padding: 36px 16px 0;
    margin: auto;
  }
  .icon-verified-house {
    path:last-of-type {
      fill: ${props => props.theme.colors.primary};
    }
  }
  .checkbox-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 0 20px;
  }
  .checkbox-wrapper {
    margin: 22px 0;
  }
  .checkbox-container {
    &__title {
      font-size: 12px;
      line-height: 20px;
      padding: 0 0 8px;
      margin: 0 0 16px;
      border-bottom: 1px solid #dadada;
    }
  }
  .checkboxes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 8px 0;
  }
  .info-block {
    display: flex;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #dadada;
    border-radius: 5px;
    margin: 0 0 20px;
    justify-content: space-between;
    align-items: center;

    &_merged-top {
      border-radius: 5px 5px 0px 0px;
      margin: 0;
    }
    &_merged-bottom {
      border-radius: 0px 0px 5px 5px;
      border-top: 0;
    }
    &__alert {
      align-items: flex-start;
      flex-direction: column;
      justify-content: stretch;
      .info-block__btn {
        min-width: 150px;
      }
      .info-block__btn:first-of-type {
        margin-right: 20px;
      }
    }
    &__title {
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      margin: 0;
      &_primary {
        color: ${props => props.theme.colors.primary};
      }
    }
    &__num {
      display: flex;
      margin: 0 20px 0 0;
      font-weight: 600;
    }
    &__btn {
      min-width: 116px;
    }
    &__btn-add.simple {
      margin: 0 0 14px;
      color: ${props => props.theme.colors.primary};
    }
    &__btn-block {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 20px 0 0;
      &_add {
        margin: 0;
        display: flex;
        justify-content: flex-end;
      }
    }
    &__edit-btn {
      cursor: pointer;
      border: none;
      background: none;
      padding: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.colors.primary};
      margin-left: auto;
      outline: none;

      &:focus {
        opacity: 0.7;
      }
    }
    &__align-right {
      text-align: right;
    }
  }
  .process-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__title {
      font-size: 12px;
      line-height: 20px;
      margin: 0 0 16px;
      font-weight: normal;
    }
    &__input-block {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(calc(50% - 20px), 1fr));
      grid-gap: 14px 20px;
      margin: 0 0 14px;
      .process-form__input-block {
        margin: 0;
      }
      &_three-columns {
        grid-template-columns: 2fr 3fr;
      }
      &_short {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &__input {
      width: 100%;

      input {
        height: 36px;
      }
    }
    &__radio-block {
      margin: 0 0 14px;
    }
    &__btn {
      min-width: 116px;
      margin: 22px 0 0 auto;
    }
    &__textarea-block {
      margin: 14px 0;
      .textarea-input {
        min-height: 80px;
      }
    }
    &__textarea {
      width: 100%;
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .checkboxes {
      &-col2 {
        grid-template-columns: repeat(2, minmax(250px, 1fr));
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .process-tabs-container {
      padding: 0;
    }
    .info-block {
      display: flex;
      padding: 12px 20px;
      &_alert {
        .info-block__btn:first-of-type {
          margin: 0 0 12px;
        }
      }
      &__title {
        font-size: 14px;
        line-height: 24px;
      }
      &__btn-block {
        flex-direction: column;
      }
      &__btn-add {
        margin: 14px 0 20px;
      }
    }
    .process-form {
      &__input-block {
        grid-template-columns: 1fr;
      }
      &__btn {
        width: 100%;
      }
    }
    .checkbox-container {
      margin: 0 0 24px;
    }
    .checkbox-wrapper {
      margin: 0;
    }
  }
`;

const StyledTabs = styled(Tabs)`
  max-width: 1025px;
  flex: 1;
  justify-content: space-between;
  .tab-item {
    color: #c4c4c4;
    border-bottom: 4px solid transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 0 0 16px;
    &.active {
      color: white;
      border-bottom: 4px solid white;
      font-weight: 600;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-wrap: nowrap;
    flex-shrink: 0;
    overflow-x: auto;
    padding: 16px 16px 0;
    margin-right: 16px;

    .tab-item {
      flex-shrink: 0;
      padding: 0 0 8px;
    }
  }
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(218, 223, 225, 0.75);
  color: #000;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
`;
